import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
  DEFAULT_SORT_KEY,
  DEFAULT_SORT_ORDER,
  NUMBER_RECORDS_PER_PAGE,
  SEARCH,
} from '@app/core/constants';
import { HttpService, SharedDataService } from '@app/core/services';
import { updateInputBindingOnChanges } from '@app/core/shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import * as constants from '@src/app/core/constants/system.constant';
import {
  FILTER_BUTTONS
} from '@src/app/core/constants/table-actions.constant';
import { Debounce } from '@src/app/core/decorator/debounce.decorator';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { AllowedTableActions } from '@src/app/core/interfaces/table-actions.interface';
import { HelperService } from '@src/app/core/services/helper.service';
import moment from 'moment';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filters: any[] = [];
  @Input() searchable: boolean = true;
  @Input() itemsExport: boolean = false;
  @Input() crmItemsExport: boolean = false;

  @Input() hideFilter: boolean = false;
  @Input() showCreateButton: boolean = false;
  @Input() showRefreshButton: boolean = false;
  @Input() searchPlaceholder: string = 'Search';
  @Input() enableTableAction: boolean = false;
  @Input() isBtnBelow: boolean = false;
  /*
   * The following used to hold the inputs for header
   * */
  @Input() title: any = null;
  @Input() link: any = null;
  @Input() queryParams: any = null;
  @Input() linkIcon: any = null;
  @Input() linkText: any = null;
  @Input() allowAction: boolean = false;
  @Input() sendIntercity: boolean = false;
  @Input() showZoneButton: boolean = false;
  @Input() allowedTableActions: AllowedTableActions = {};
  @Input() isActionOnFilterApply: boolean = false;
  @Input() showAddDropDown: boolean = false;
  @Input() isRecordsFound: boolean = false;
  @Input() exportDisabledOnNoRecord: boolean = false;
  @Input() addDropDownData: any[] = [];
  intercityKey: string = 'filter.intercity';
  private actionsConfig: Array<{
    actionName: string;
    key: keyof AllowedTableActions;
    icon: string;
  }> = [
    { actionName: 'Activate', key: 'active', icon: 'tick-circle' },
    { actionName: 'Deactivate', key: 'inactive', icon: 'close-circle' },
    { actionName: 'Published', key: 'published', icon: 'tick-circle' },
    { actionName: 'UnPublished', key: 'unpublished', icon: 'close-circle' },
    // { actionName: 'Set as Draft', key: 'draft', icon: 'minus-cirlce' },
    // { actionName: 'Set as Pending', key: 'pending', icon: 'minus-cirlce' },
    { actionName: 'Delete', key: 'delete', icon: 'trash' },
    { actionName: 'Download Template', key: 'download_template', icon: 'document-download' },
    {
      actionName: 'Download Filtered Rows',
      key: 'download_selected_rows',
      icon: 'document-download',
    },
    { actionName: 'Import', key: 'import', icon: 'import' },
    { actionName: 'Export', key: 'export', icon: 'export' },
    { actionName: 'Export', key: 'localExport', icon: 'export' },
  ];

  public tableActions: Array<{ actionName: string; key: keyof AllowedTableActions; icon: string }> =
    [];

  /**
   * the following is used to define how many fields to be shown in one row
   */
  @Input() filtersPerRow = 3;
  @Input() maxFiltersOnFirstRow!: number;

  /**
   * the following used to show the search button and stop auto api calling on text field
   */
  @Input() showSearchButton = true;

  @Output() searchClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() tableAction: EventEmitter<any> = new EventEmitter<any>();

  public params: any = {};
  public _filters: any[] = [];
  public isOpenFilter: boolean = false;
  public formGroup: FormGroup = new FormGroup({});

  private showFilters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _onDestroy$: Subject<void> = new Subject<void>();

  /**
   * Constructs a new instance of the class.
   *
   * @param {HttpService} httpService - The HTTP service for making HTTP requests.
   * @param {SharedDataService} sharedDataService - The service for sharing data across components.
   * @param {TranslateService} translate - The service for translating text.
   * @param {HelperService} helperService - The service for providing helper functions.
   */
  constructor(
    private httpService: HttpService,
    public sharedDataService: SharedDataService,
    private translate: TranslateService,
    private helperService: HelperService,
  ) {}

  /**
   * Initializes the component and sets up the initial state.
   *
   * This function is called when the component is initialized. It performs the following tasks:
   * - Initializes the filters by calling the `initFilters` function.
   * - Generates the actions array by calling the `generateActionsArray` function.
   * - Subscribes to the `searcgBarValue` observable of the `sharedDataService` and sets the `params[SEARCH]` value to the emitted value.
   * - After a delay of 200 milliseconds, it emits a search event by calling the `emitSearchEvent` function.
   *
   * @return {void} This function does not return a value.
   */
  ngOnInit(): void {
    this.initFilters();
    this.generateActionsArray();
    this.sharedDataService.searcgBarValue.subscribe(value => {
      this.params[SEARCH] = value;
      setTimeout(() => {
        this.emitSearchEvent();
      });
    });
  }

  formateThis(item: any, field: string) {
    if (this.sharedDataService.currentLanguage === 'fr' && item['frenchName']) {
      return item['frenchName'];
    } else {
      return item[field] || item['name'];
    }
  }

  getNestedProperty(obj: any, path: string) {
    return path.split('.').reduce((o, p) => (o ? o[p] : undefined), obj);
  }

  formateThiss(item: any, field: string) {
    if (this.sharedDataService.currentLanguage === 'fr' && item['frenchName']) {
      return item['frenchName'];
    } else {
      return this.getNestedProperty(item, field) || item['name'];
    }
  }

  /**
   * Executes when input properties change.
   *
   * @param {SimpleChanges} changes - An object containing the changed input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    updateInputBindingOnChanges(this, changes);
  }

  /**
   * Executes when the component is destroyed.
   * Completes the `_onDestroy$` subject and cleans up any subscriptions to `searchInputTerm$`.
   *
   * This function does not take any parameters.
   *
   * @return {void} This function does not return a value.
   */
  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.filters
      ?.filter((f: any) => (f.searchInputTerm$ ? true : false))
      .forEach((f: any) => {
        f.searchInputTerm$.next('');
        f.searchInputTerm$.complete();
      });
  }

  /**
   * Generates the actions array based on the allowed table actions.
   *
   * This function iterates over the `actionsConfig` array and checks if each action's key is present in the `allowedTableActions` object. If it is, the action is added to the `tableActions` array.
   *
   * @private
   * @return {void} This function does not return a value.
   */
  private generateActionsArray() {
    this.tableActions = [];

    this.actionsConfig.forEach(config => {
      const { key } = config;

      if (this.allowedTableActions[key]) {
        this.tableActions.push(config);
      }
    });
  }
  /**
   * Applies a filter to the data based on the given filter object and index.
   *
   * @param {any} filter - The filter object containing information about the filter to apply.
   * @param {number} index - The index of the filter in the filters array.
   * @return {void} This function does not return a value.
   */
  onApplyFilter(filter: any, index: number) {
    const value = this.filters.filter(
      (r: any) =>
        r.mutualKey === 'filter.timetable.lineDate' ||
        r.resetKey === 'filter.startAt' ||
        r.resetKey === 'filter.startDate',
    );
    if (value.length) {
      value.map((r: any) => {
        this.formGroup.controls[r.key].setValue(null);
      });
    }

    let filterStr = '';

    // Handle date-from and date-to filters
    if (filter.type === 'date-from') {
      const dateFrom = this.formGroup.get(filter.key)?.value;
      const dateTo = this.formGroup.get('filter.date-to')?.value; // Assuming 'filter.date-to' key

      if (dateFrom && dateTo) {
        // Date range is fully defined
        filterStr = `${moment(dateFrom).format('YYYY-MM-DD')}, ${moment(dateTo).format('YYYY-MM-DD')}`;
      } else if (dateFrom) {
        // Only date-from is defined
        filterStr = `${moment(dateFrom).format('YYYY-MM-DD')}`;
      }
    } else if (filter.type === 'date-to') {
      const dateTo = this.formGroup.get(filter.key)?.value;
      const dateFrom = this.formGroup.get('filter.date-from')?.value; // Assuming 'filter.date-from' key

      if (dateFrom && dateTo) {
        // Date range is fully defined
        filterStr = `${moment(dateFrom).format('YYYY-MM-DD')}, ${moment(dateTo).format('YYYY-MM-DD')}`;
      } else if (dateTo) {
        // Only date-to is defined
        filterStr = `${moment(dateTo).format('YYYY-MM-DD')}`;
      }
    } else {
      const date = moment().format('YYYY-MM-DD'); // Default date logic
      if (filter.type === 'upcoming' && filter.key === 'filter.startDate') {
        filterStr = moment(date, 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DD HH:mm');
      } else if (filter?.type === 'yesterday') {
        const startDate = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
        const endDate = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm');
        filterStr = `${startDate}, ${endDate}`;
      } else if (filter?.type === 'sevenDays') {
        const startDate = moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
        const endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm');
        filterStr = `${startDate}, ${endDate}`;
      } else if (filter?.type === 'today') {
        const startDate = moment().startOf('day').format('YYYY-MM-DD HH:mm');
        const endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm');
        filterStr = `${startDate}, ${endDate}`;
      } else if (filter?.type === 'tomorrow') {
        const startDate = moment().add(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
        filterStr = `${startDate}`;
      } else {
        filterStr = `${date}`;
      }
    }

    this.setFilterValue(filterStr, filter.key, false, index);
  }

  /**
   * Initializes the filters by adding form controls, setting up autocomplete filters, and preparing other incoming filters.
   *
   * @return {void} This function does not return a value.
   */
  initFilters(): void {
    setTimeout(() => {
      this.filters.forEach((f: any, i: number) => {
        const validators: any[] = [];

        // Add specific logic for date-from and date-to filters
        if (f.type === 'date-from') {
          validators.push(
            Validators.max(
              f.maxTo && this.formGroup.controls[f.maxTo]?.value
                ? new Date(this.formGroup.controls[f.maxTo].value).getTime()
                : Number.MAX_VALUE
            )
          );
        } else if (f.type === 'date-to') {
          validators.push(
            Validators.min(
              f.minFrom && this.formGroup.controls[f.minFrom]?.value
                ? new Date(this.formGroup.controls[f.minFrom].value).getTime()
                : Number.MIN_VALUE
            )
          );
        }

        // Add the form control with validations
        this.addFormControlWithValidations(f.key, validators, false, f.value);

        // For date-from and date-to: Set up dynamic validation
        if (f.type === 'date-from' || f.type === 'date-to') {
          this.formGroup.controls[f.key].valueChanges.subscribe((newValue) => {
            if (f.type === 'date-from' && f.maxTo) {
              const maxControl = this.formGroup.controls[f.maxTo];
              if (maxControl) {
                maxControl.setValidators([
                  Validators.required,
                  Validators.min(new Date(newValue).getTime()),
                ]);
                maxControl.updateValueAndValidity();
              }
            }

            if (f.type === 'date-to' && f.minFrom) {
              const minControl = this.formGroup.controls[f.minFrom];
              if (minControl) {
                minControl.setValidators([
                  Validators.required,
                  Validators.max(new Date(newValue).getTime()),
                ]);
                minControl.updateValueAndValidity();
              }
            }
          });
        }

        if (f.type === 'autocomplete') {
          f.results = [];
          f._results = [];
          f.searchInputTerm$ = new Subject<string>();
          f.searchInputTerm$
            .pipe(
              debounceTime(200),
              distinctUntilChanged(),
              tap(() => {
                this.toggleAutocompleteFilterLoader(f, true);
              }),
              switchMap((term) => {
                return of(term || '');
              }),
            )
            .subscribe((term: string = '') => {
              if (term?.length >= 0) {
                this.onInputChange(term, f, true, i);
              } else {
                this.toggleAutocompleteFilterLoader(f, false);
              }
            });
          // checking for prefetch autocomplete results
          if (f.preFetch) {
            this.onInputChange('', f, true, i);
          }
        }
      });
      // now we prepare other incoming filters
      this._filters = this.getChunkArray(
        this.filters,
        this.filtersPerRow,
        this.maxFiltersOnFirstRow,
      );
      this.setupFilters();
    }, 200);
  }

  /**
   * Updates the selected option and value for an autocomplete filter based on the provided value.
   * If the value is falsy, the selected option and value are set to null and the filter key is deleted from the params.
   * If the value is truthy, the selected option and value are updated based on the filter's filterKey and operation properties.
   * If the filter has a filterKey and the value is an array, the values without the $in: prefix are extracted and joined with a comma and the operation prefix.
   * If the filter has a filterKey and the value is not an array, the value is converted to a string and the operation prefix is added.
   * If currentValue is provided, the concatenated value is set under the filter key in the params.
   *
   * @param {any} value - The new value for the autocomplete filter.
   * @param {any} filter - The autocomplete filter object.
   * @param {any} currentValue - The current value of the autocomplete filter (optional).
   */
  onAutocompleteChanged(value: any, filter: any, currentValue?: any) {
    if (!value) {
      filter.selected_option = filter.selectedValue = null;
      Reflect.deleteProperty(this.params, filter.key);
    } else {
      let selectedRoles;

      if (filter.filterKey && Array.isArray(value)) {
        // Extract only the values without the $in: prefix
        selectedRoles = value.map((v: any) => v[filter.filterKey]);
        selectedRoles = (filter.operation || '') + selectedRoles.join(','); // Join roles with comma and add the operation prefix
      } else {
        selectedRoles = filter.filterKey
          ? (filter.operation || '') + value[filter.filterKey].toString()
          : value;
      }

      filter.selected_option = filter.selectedValue = selectedRoles;
      if (currentValue) {
        // Set the concatenated value under the filter key in the params
        this.params[filter.key] = selectedRoles;
      }
    }
  }

  /**
   * Handles the change event when a select input value is changed.
   *
   * @param {any} value - The new value selected in the select input.
   * @param {any} filter - (optional) The filter object associated with the select input. Defaults to an object with an empty key.
   * @param {number} index - The index of the filter in the filters array.
   * @return {void}
   */
  onSelectChange(value: any, filter: any = { key: '' }, index: number) {
    if (!value) {
      value = false;
    } else {
      if (Array.isArray(value)) {
        value = value?.map((v: any) => v?.value);
      } else {
        value = value?.value;
      }
    }
    this.setFilterValue(this.helperService.clone(value), filter.key, false, index);
  }

  /**
   * Handles the change event of an input field.
   *
   * @param {string | null} value - The new value of the input field.
   * @param {any} filter - The filter object associated with the input field.
   * @param {boolean} [preFetch=false] - Indicates whether to pre-fetch data.
   * @param {number} index - The index of the input field.
   * @return {void} This function does not return anything.
   */
  onInputChange(value: string | null, filter: any, preFetch: boolean = false, index: number) {
    if (filter.type === 'autocomplete') {
      filter.selectedValue = null;
      filter.selected_option = null;
    }
    this.setFilterValue(value, filter.key, preFetch, index);
  }
  /**
   * Handles the change event when a date input value is changed.
   *
   * @param {any} value - The new value of the date input.
   * @param {any} filter - The filter object associated with the date input.
   * @param {number} index - The index of the filter in the filters array.
   * @return {void}
   */
  onDateChange(event: any, filter: any, index: number) {
    const value = event.target.value;

    if (filter.key === 'filter.dateFrom') {
      this.formGroup.controls['filter.dateFrom'].setValue(value);

      // Validate date-to against date-from
      const dateToControl = this.formGroup.controls['filter.dateTo'];
      if (dateToControl && new Date(value) > new Date(dateToControl.value)) {
        dateToControl.setValue(null);
      }
    } else if (filter.key === 'filter.dateTo') {
      this.formGroup.controls['filter.dateTo'].setValue(value);

      // Validate date-from against date-to
      const dateFromControl = this.formGroup.controls['filter.dateFrom'];
      if (dateFromControl && new Date(value) < new Date(dateFromControl.value)) {
        dateFromControl.setValue(null);
      }
    }

    // Get the selected date range values
    const dateFrom = this.formGroup.controls['filter.dateFrom'].value;
    let dateTo = this.formGroup.controls['filter.dateTo'].value;

    // Adjust the dateTo value to 23:59:00 if it exists
    if (dateTo) {
      const dateObj = new Date(dateTo);
      dateObj.setHours(23, 59, 0, 0);  // Set to 23:59:00 of the selected date
      dateTo = dateObj.toISOString();
    }

    // Use the `filterKey` dynamically instead of hardcoding 'filter.createdAt'
    const filterKey = filter?.filterKey || 'filter.createdAt'; // Default to 'filter.createdAt' if not specified

    // Update params for filter.createdAt
    if (dateFrom && dateTo) {
      // Use `$btw` when both dates are selected
      this.params[filterKey] = `$btw:${dateFrom},${dateTo}`;
    } else if (dateFrom) {
      // Use `$gte` when only date-from is selected
      this.params[filterKey] = `$gte:${dateFrom}`;
    } else if (dateTo) {
      // Use `$lte` when only date-to is selected
      this.params[filterKey] = `$lte:${dateTo}`;
    } else {
      // Remove the filter if both are empty
      Reflect.deleteProperty(this.params, filterKey);
    }

    // Trigger validation for Apply button
    this.formGroup.updateValueAndValidity();
  }

  getControlValue(controlName: string): any {
    return this.formGroup?.controls[controlName]?.value || null;
  }

  padZero = (num: number) => (num < 10 ? `0${num}` : num);

  /**
   * Emits the given action to the tableAction output.
   *
   * @param {any} action - The action to emit.
   * @return {void} This function does not return anything.
   */
  onEmitAction(action: any) {
    if (
      (action?.key === 'export' && this.isApplyButtonDisabled() && this.isActionOnFilterApply) ||
      (!this.isRecordsFound && this.exportDisabledOnNoRecord)
    ) {
      if (!this.isRecordsFound && this.exportDisabledOnNoRecord) {
        this.httpService.showMessage(
          this.translate.instant('MSGS.GENERAL.NO_RESULTS_FOUND'),
          'warning',
        );
      }
      return;
    }
    this.tableAction.emit(action);
  }

  /**
   * Emits the given button type to the exportClick output.
   *
   * @param {string} btnType - The button type to emit.
   * @return {void} This function does not return anything.
   */
  getBtnClickType(btnType: string) {
    this.exportClick.emit(btnType);
  }
  /**
   * Resets the filters based on the given filter object.
   *
   * @param {any} filter - The filter object containing the reset filters.
   */
  resetFilters(filter: any) {
    const filter_buttons = [
      ...FILTER_BUTTONS
    ];
    if (filter?.resetFilters) {
      filter.resetFilters.map((data: any) => {
        const id = this.filters.findIndex(l => l.key === data);
        if (id !== -1) {
          this.filters[id].selectedValue = '';
        }
        if (this.filters[id] && this.params.hasOwnProperty(this.filters[id].key)) {
          Reflect.deleteProperty(this.params, this.filters[id].key);
        }
      });
    }

    const isFromFilterButtons = filter_buttons.findIndex(t => filter.type === t.type);
    if (isFromFilterButtons !== -1) {
      const filterSelectedButtons = filter_buttons.filter(
        t => filter.type !== t.type && t.selectedValue,
      );

      filterSelectedButtons.forEach(t => {
        const id = this.filters.findIndex(l => l.type === t.type);
        if (id !== -1) {
          this.filters[id].selectedValue = '';
        }
        if (t['type'] === 'current') {
          if (this.params.hasOwnProperty(this.filters[id].key)) {
            Reflect.deleteProperty(this.params, this.filters[id].key);
          }
        } else {
          if (this.filters[id] && this.params.hasOwnProperty(this.filters[id].key)) {
            Reflect.deleteProperty(this.params, this.filters[id].key);
          }
        }
      });
    }
  }

  /**
   * Sets the filter value for a specific filter.
   *
   * @param {any | null} value - The new value for the filter.
   * @param {string} filterKey - The key of the filter.
   * @param {boolean} preFetch - Indicates whether to pre-fetch the filter value.
   * @param {number} index - The index of the filter in the filters array.
   */
  setFilterValue(
    value: any | null,
    filterKey: string = '',
    preFetch: boolean = false,
    index: number,
  ) {
    if (filterKey) {
      if (index > -1) {
        const filter = this.filters[index];
        this.resetFilters(filter);
        if (filter.type === 'autocomplete') {
          if (filter.apiUrl && (value || preFetch)) {
            const params: any = {
              page: 1,
              // fromDropdown: true,
              sortBy:
                (filter.sortBy || DEFAULT_SORT_KEY) +
                ':' +
                (filter.sortOrder || DEFAULT_SORT_ORDER),
              limit: filter.size || NUMBER_RECORDS_PER_PAGE,
            };
            params[SEARCH] = value;
            if (filter.params && Array.isArray(filter.params)) {
              filter.params.forEach((p: any) => {
                params[p.key] = p.value;
              });
            }
            if (
              filter.relatedFilters &&
              Array.isArray(filter.relatedFilters) &&
              filter.relatedFilters.length > 0
            ) {
              filter.relatedFilters.forEach((k: any) => {
                const relatedFilter = this.filters.find(f => f.key === k);
                if (relatedFilter && relatedFilter.selected_option && this.params[k]) {
                  params[k] = this.params[k];
                }
              });
            }
            if (filter.requireFilter || filter.appendParam) {
              // Checking if filter is dependant on other related filter
              if (filter.appendParam) {
                if (filter.requireFilter && this.params[filter.requireFilter]) {
                  params[filter.appendParam] = this.params[filter.requireFilter];
                }
              }
            }
            if (filter.sendIntercity) {
              params[filter.intercityKey || this.intercityKey] =
                this.sharedDataService.selectIntercity || false;
            }
            this.toggleAutocompleteFilterLoader(filter, true);
            this.httpService
              .requestEntity(
                filter.method || 'POST',
                filter.apiUrl,
                params,
                this.httpService.formDataHeaders,
                false,
              )
              .pipe(takeUntil(this._onDestroy$))
              .subscribe({
                /**
                 * Updates the filter results and toggles the autocomplete filter loader.
                 *
                 * @param {any} res - The response object containing the filter data.
                 * @return {void} This function does not return a value.
                 */
                next: (response: unknown) => {
                  const res = response as ApiResponseInterface<any>;

                  res.body.data = res?.body?.data?.map((item: any) => {
                    if (item?.firstName && item?.lastName) {
                      return { ...item, fullName: `${item.firstName} ${item.lastName}` };
                    }
                    return item;
                  });
                  this.toggleAutocompleteFilterLoader(filter, false);
                  let responseData = res.body?.data || res.body;
                  if (responseData && responseData.length > 0) {
                    if (filter.formatResult) {
                      responseData = filter.formatResult(responseData);
                    } else if (filter.fields?.length > 0) {
                      responseData = responseData.map((i: any) => {
                        i[filter.field || 'name'] =
                          filter.fields
                            .map((f: string) => i[f] || '')
                            .join(filter.separator || ' ')
                            .trim() || '';
                        return i;
                      });
                    }
                  }
                  // Update the selected value based on your concatenation logic
                  this.onAutocompleteChanged(responseData, filter, value);

                  filter.results = filter._results = responseData || [];
                  this.filters[index] = filter;
                },
                error: err => {
                  this.toggleAutocompleteFilterLoader(filter, false);
                  this.filters[index] = filter;
                },
                /**
                 * Completes the autocomplete filter loader and updates the filter in the filters array.
                 *
                 * @param {void} - No parameters.
                 * @return {void} - No return value.
                 */
                complete: () => {
                  this.toggleAutocompleteFilterLoader(filter, false);
                  this.filters[index] = filter;
                },
              });
          } else {
            filter['selectedValue'] = null;
            if (this.params.hasOwnProperty(filter.key)) {
              Reflect.deleteProperty(this.params, filter.key);
            }
            this.filters[index] = filter;
          }
        } else {
          if (value != null) {
            this.filters[index]['selectedValue'] = value;
            this.params[filterKey] = (filter?.operation || '') + value;
          } else {
            filter['selectedValue'] = null;
            if (this.params.hasOwnProperty(filterKey)) {
              Reflect.deleteProperty(this.params, filterKey);
            }
          }
          this.filters[index] = filter;
        }
      }
    }
  }

  /**
   * Resets the form and clears the search parameter.
   * Also resets all autocomplete filters, option filters, and filters of specific types.
   * If a filter has a value, it is patched back to the form control.
   *
   * @return {void}
   */
  resetForm(): void {
    this.formGroup.reset();
    this.params = { ...(this.params?.search ? { search: this.params.search } : {}) };

    // this.filters
    //   .filter(f => f.type === 'autocomplete')
    //   .forEach(f => {
    //     this.toggleAutocompleteFilterLoader(f, false);
    //     f.selectedValue = null;
    //     f.selected_option = null;
    //     if (f.data) {
    //       f.data = null;
    //     }
    //   });
    // this.filters.forEach(f => {
    //   if (f.type === 'current' || f.type === 'upcoming' || f.type === 'expired') {
    //     f.selectedValue = null;
    //   }
    //   if (f.value) {
    //     setTimeout(() => {
    //       this.formGroup.controls[f.key].patchValue(f.value);
    //     });
    //   }
    // });
    const autocompleteFilters = this.filters.filter(
      f => f.type === 'autocomplete' || f.type === 'date',
    );
    autocompleteFilters.forEach(f => {
      this.toggleAutocompleteFilterLoader(f, false);
      f.selectedValue = null;
      f.selected_option = null;
      if (f.data) {
        f.data = null;
      }
    });
    const optionFilters = this.filters.filter(f => f.type === 'option');
    optionFilters.forEach(f => {
      f.selectedValue = null;
    });
    const typesToReset = [
      'past',
      'current',
      'upcoming',
      'expired',
      'yesterday',
      'today',
      'tomorrow',
      'sevenDays',
    ];
    for (const f of this.filters) {
      if (typesToReset.includes(f.type)) {
        f.selectedValue = null;
      }
      if (f.value) {
        setTimeout(() => {
          this.formGroup.controls[f.key].patchValue(f.value);
        });
      }
    }
    this.emitButtonEvent(true);
  }

  /**
   * Emits a search event with the current parameters and filters.
   *
   * @param {boolean} reset - Whether to reset the parameters and filters. Defaults to false.
   * @return {void} This function does not return a value.
   */
  emitSearchEvent(reset: boolean = false) {
    const paramsKeyList: string[] = Object.keys(this.params);
    if (paramsKeyList.length > 0 || reset) {
      paramsKeyList
        .filter((k: string) =>
          k !== 'search' &&
          this.params.hasOwnProperty(k) &&
          (this.params[k] === '' || this.params[k] === null || this.params[k] === undefined)
            ? true
            : false,
        )
        .forEach((k: string) => {
          Reflect.deleteProperty(this.params, k);
        });
      this.searchClick.emit({ params: this.params, filters: this.filters });
    }
  }

  /**
   * Emits a button event with the current parameters and filters.
   *
   * @param {boolean} reset - Whether to reset the parameters and filters. Defaults to false.
   * @return {void} This function does not return a value.
   */
  emitButtonEvent(reset: boolean = false) {
    const paramsKeyList: string[] = Object.keys(this.params);
    if (paramsKeyList.includes('search') && paramsKeyList.length == 1 && reset == false) {
      this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.SELECT_FILTER'), 'warning'); // SELECT_FILTER
      return;
    } else if (!paramsKeyList.includes('search') && paramsKeyList.length < 1 && reset == false) {
      this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.SELECT_FILTER'), 'warning');
      return;
    }

    if (paramsKeyList.length > 0 || reset) {
      paramsKeyList
        .filter((k: string) => {
          const filter = this.filters.find((f: any) => f.key === k);
          return k !== 'search' &&
            this.params.hasOwnProperty(k) &&
            (this.params[k] === '' ||
              this.params[k] === null ||
              this.params[k] === undefined ||
              this.params[k] === filter?.operation)
            ? true
            : false;
        })
        .forEach((k: string) => {
          Reflect.deleteProperty(this.params, k);
        });
      this.sharedDataService.buttonClick.emit({ params: this.params, filters: this.filters });
      this.buttonClick.emit({ params: this.params, filters: this.filters, reset: reset });
    }
  }

  /**
   * Checks if the apply button should be disabled based on the current parameters.
   *
   * @return {boolean} True if the apply button should be disabled, false otherwise.
   */
  isApplyButtonDisabled(): boolean {
    const paramsKeyList: string[] = Object.keys(this.params);

    if (
      (paramsKeyList.includes('search') && paramsKeyList.length === 1) ||
      (!paramsKeyList.includes('search') && paramsKeyList.length < 1)
    ) {
      return true;
    }

    return false;
  }

  /**
   * Handles the click event on the search bar.
   *
   * @param {string | null | any} value - The value entered in the search bar. Defaults to an empty string.
   * @return {void} This function does not return a value.
   */
  @Debounce(constants.DEFAULT_DEBOUNCE_TIME_1_SEC)
  onSearchBarClick(value: string | null | any = '') {
    this.params[SEARCH] = value;
    this.emitSearchEvent();
  }

  /**
   * The following adds the respective control with its respective form validation
   * @param formElement
   * @param validations
   * @param disabled
   * @param value
   */
  private addFormControlWithValidations(
    formElement: string,
    validations: ValidatorFn[] = [],
    disabled = false,
    value: any = null,
  ): void {
    if (this.formGroup.contains(formElement)) {
      this.formGroup.controls[formElement].patchValue(value, { emitEvent: false, onlySelf: true });
      this.formGroup.controls[formElement].setErrors(null, { emitEvent: false });
      this.formGroup.controls[formElement].setValidators(validations);
      if (this.formGroup.controls[formElement].enabled && disabled) {
        this.formGroup.controls[formElement].disable({ emitEvent: false, onlySelf: true });
      }
    } else {
      this.formGroup.addControl(
        formElement,
        new FormControl({ value: value, disabled: disabled }, validations),
      );
    }
    this.formGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.formGroup.markAsTouched({ onlySelf: true });
  }

  /**
   * Sets up the filters by calling the getChunkArray method to chunk the filters array into smaller arrays based on the filtersPerRow and maxFiltersOnFirstRow parameters.
   * Emits a value of true to the showFilters subject to indicate that the filters have been populated.
   *
   * @return {void} This function does not return a value.
   */
  private setupFilters(): void {
    this._filters = this.getChunkArray(this.filters, this.filtersPerRow, this.maxFiltersOnFirstRow);
    // emit change as filters have been populated
    this.showFilters.next(true);
  }

  /**
   * Splits an array into smaller chunks of a specified size.
   *
   * @param {any[]} array - The array to be chunked.
   * @param {number} [chunkSize=2] - The size of each chunk. Default is 2.
   * @param {number} [maxFiltersOnFirstRow=0] - The maximum number of filters to display on the first row. Default is 0.
   * @return {any[]} An array of arrays, where each inner array represents a chunk of the original array.
   */
  private getChunkArray(
    array: any[],
    chunkSize: number = 2,
    maxFiltersOnFirstRow: number = 0,
  ): any[] {
    if (!array) {
      return array;
    }
    const chunkedMaps = [];
    const tmpArray = Array.from(array);
    for (let i = 0; i < array.length; i += chunkSize) {
      let chunked;
      // the following logic of chunkSize =2 is for the use case where we show 3 in the first row
      // and then 2 on each subsequent rows. for this to work its important that fxFlex is provided against the rows which have 2 columns
      if (chunkSize === 2 && i === 0) {
        chunked = tmpArray.slice(i, i + chunkSize + (maxFiltersOnFirstRow === 2 ? 0 : 1));
      } else if (chunkSize === 2) {
        chunked = tmpArray.slice(i + (maxFiltersOnFirstRow === 2 ? 0 : 1), i + chunkSize + 1);
      } else {
        chunked = tmpArray.slice(i, i + chunkSize);
      }
      chunkedMaps.push(chunked);
    }
    return chunkedMaps;
  }

  /**
   * Toggles the loading state of an autocomplete filter.
   *
   * @param {any} f - The filter object.
   * @param {boolean} bool - The desired loading state.
   * @return {void} This function does not return a value.
   */
  private toggleAutocompleteFilterLoader(f: any, bool: boolean): void {
    if (f.hasOwnProperty('loading') && f.loading !== bool) {
      f.loading = bool;
    }
  }
}
