import { Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_STATUS, COMMON_MODULES } from '@src/app/core/constants';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { BaseComponent } from '@src/app/core/base';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';

@Component({
  selector: 'status',
  standalone: true,
  templateUrl: './app-status.component.html',
  styleUrls: ['./app-status.component.scss'],
  imports: [...COMMON_MODULES, NgbDropdownModule, ConfirmDialogModule],
})
export class AppStatus extends BaseComponent {
  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  public confirmEventAction: 'active' | 'delete' | null = null;

  @Input() element: any;
  @Input() data: any;

  @Input() column: any;
  @Input() options: any;
  @Input() canUpdateStatus: boolean = true;

  @Output() updatedStatus: EventEmitter<any> = new EventEmitter();

  public status = APP_STATUS;
  currentState: any = 0;
  isChangeOnLocal: boolean = false;
  /**
   * Initializes a new instance of the class.
   *
   * @param {Injector} injector - The injector used for dependency injection.
   */
  constructor(injector: Injector) {
    super(injector);
    console.log(this.element?.isActivated);
  }

  /**
   * Updates the status of an element and emits an event with the updated status, element, and data.
   *
   * @param {string} status - The new status for the element. If 'pending', it will be changed to 'draft'.
   * @param {any} element - The element whose status is being updated.
   * @return {void} This function does not return anything.
   */
  public updateStatus(status: string, element: any) {
    status = status == 'pending' ? 'draft' : status;
    this.updatedStatus.emit({ status: status, element: element, data: this.data });
  }

  /**
   * Returns the value of the element based on its type and employeeId.
   * If the element's type is 'system', it sets the element's disable property to true.
   * Otherwise, it sets the element's disable property to false.
   * If the element has an employeeId, it updates the element's status to 'pending' if it is 'draft',
   * otherwise it keeps the current status.
   * Returns the first matching option from the options array based on the element's status,
   * or an object with the element's status as the value if no match is found.
   *
   * @param {any} element - The element to get the value from.
   * @return {any} The value of the element.
   */


  returnValue(element: any) {
    // Handle disable logic
    if (element?.type === 'system' || element?.status === 'unverified') {
        element.disable = true;
    } else {
        element.disable = false;
    }

    // Handle defaultTitle logic for isActivated
    if (this.column?.defaultTitle) {
        const data = element?.isActivated
            ? this.options.find((o: any) => o.value === 'active')
            : this.options.find((o: any) => o.value === 'inactive');

        return data;
    }

    // Map isPublished to status and icon
    if (element?.isPublished !== undefined) {
        const mappedStatus = element.isPublished ? 'Published' : 'Unpublished';
        const data = this.options.find((o: any) => o.value === mappedStatus);

        // Return the data with the icon if it exists
        return data || {
            value: mappedStatus,
            label: mappedStatus,
            icon: element.isPublished ? 'tick-circle' : 'close-circle',
        };
    }

    if (element?.proposal !== undefined) {
      const mappedStatus = this.getValue(element, this.column.keyValue);
      return this.options.find((o: any) => o.value === mappedStatus);
    }

    // Default mapping for other statuses
    const data = this.options?.find(
        (o: any) => o?.value === (this.column.keyValue ? element[this.column.keyValue] : element?.status),
    );

    return (
        data || {
            value: this.column.keyValue ? element[this.column.keyValue] : element?.status || 'UNKNOWN',
        }
    );
}
getValue(element: any, key: string) {
  const keys = key.split('.');  // Split the key string into parts
  let value = element;
  
  keys.forEach(keyPart => {
    value = value?.[keyPart];  // Traverse each part in the path
  });

  return value || 'pending';  // Fallback to 'pending' if value is null or undefined
}


  /**
   * Process the modal close event.
   *
   * @param {any} event - The event object containing the action and data.
   * @return {void} This function does not return anything.
   */
  public processModalClose(
    event: {
      data: any;
      value?: string;
      eventType: string | null;
      confirmed: boolean;
      action: 'confirm' | 'dismiss';
    },
    isStatus?: boolean,
  ) {
    if (event.value === 'inactive') {
      this.updateStatus(event.value, this.data?.id);
    } else if (event.action === 'confirm') {
      this.updateStatus(event.data.value, this.data.id);
    } else if (isStatus && event.value) {
      this.updateStatus(event.value, this.data?.id);
    }
  }

  /**
   * Process the modal close event.
   *
   * @param {any} event - The event object containing the action and data.
   * @return {void} This function does not return anything.
   */
  public onChangeStatus(index: any) {
    this.isChangeOnLocal = true;
    this.currentState = index;
    this.processModalClose(this.options[index], true);
  }
  //inactive
  /**
   * Opens a confirmation modal with the given action.
   *
   * @param {any} action - The action to be performed.
   */
  openConfirmationModal(action: any) {
    action.actionName = action?.status;
    action.data = action?.id;
    if (this.confirmDialCompRef) {
      this.confirmEventAction = action?.actionName?.toLowerCase();
      this.confirmDialCompRef.openDialog(action);
    }
  }

  public getMessage(): string {
    // Check if column.keyValue exists
    if (this.column?.keyValue && this.element) {
      // Dynamically fetch the value based on keyValue
      const status = this.element[this.column.keyValue];
      return status ? this.column.alert : this.column.alertSecond;
    }

    if (this.element && 'status' in this.element) {
            return this.element.status === 'active' ? this.column.alert : this.column.alertSecond
          }

    // Check for fallback key 'isActivated' for active/inactive logic
    if (this.element && 'isActivated' in this.element) {
      return this.element.isActivated ? this.column.alert : this.column.alertSecond;
    }

    // Fallback to generic confirmation message
    return this.translate.instant('MSGS.GENERAL.ARE_YOU_SURE');
  }

  isSameStatus(item: any): boolean {
    const currentStatus = this.returnValue(this.element)?.value;
    return item?.value === currentStatus;
  }

}
